<template>
  <CCard>
    <CCardBody>
      <h3>{{ operationName }} Guest Group</h3>
      <CAlert v-if="message" :color="alertType"> {{ message }}</CAlert>
      <CRow>
        <CCol col="10"></CCol>
        <CCol class="text-right">
          <div><label>Active</label></div>
          <CSwitch color="success" size="lg" :checked.sync="guestGroup.active"></CSwitch>
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <CInput label="Group Name" type="text" v-model="guestGroup.name" />
        </CCol>
      </CRow>
      <CRow style="left: 5vw">
        <CCol style="display: inherit">
          <label for="mainContact">
            <h4>Must specify main contact name:</h4>
          </label>
          <CSwitch color="success" size="lg" id="mainContact" style="margin-left: 1vw"
            :checked.sync="guestGroup.askOnlyForMainContact"></CSwitch>
        </CCol>
        <CCol>
          <CCol class="d-flex justify-content-end">
            <CButton color="secondary" class="btn-lg" @click="goBack" style="margin-right: 15px">
              <CIcon name="cil-list" /> Back to the list
            </CButton>

            <CButton color="success" v-if="guestGroup.id != emptyGuid" @click="
              guestTypeDefinitionModal = true;
            modalType = 'success';
            modalAction = 'add';
            modalTitle = 'Add new guest type definition';
                                                      " style="margin-right: 15px" class="btn-lg">
              <CIcon name="cil-tags" /> Add Guest Type Definition
            </CButton>
            <CButton color="primary" @click="save()" style="margin-right: 15px" class="btn-lg">
              <CIcon name="cil-save" /> Save
            </CButton>
          </CCol>
        </CCol>
      </CRow>

      <CModal v-model="guestTypeDefinition.id" :title="modalTitle" :color="modalType"
        :show.sync="guestTypeDefinitionModal" @update:show="closeModal">
        <CRow>
          <CCol col="6">
            <CSelect label="Guest Types" :value.sync="guestTypeDefinition.guestTypeId" :plain="true"
              :options="guestTypes">
            </CSelect>
          </CCol>

          <CCol col="6">
            <CInput label="Minimum Guest Amount" v-model="guestTypeDefinition.minGuests" />
          </CCol>

          <CCol col="6">
            <CInput label="Maximum Guest Amount" v-model="guestTypeDefinition.maxGuests" />
          </CCol>

          <CCol col="6">
            <CInput label="Minimum Age" v-model="guestTypeDefinition.minAge" />
          </CCol>

          <CCol col="6">
            <CInput label="Maximum Age" v-model="guestTypeDefinition.maxAge" />
          </CCol>

          <CCol col="12">
            <CInputCheckbox label="Must Specify Age" :checked.sync="guestTypeDefinition.mustSelectAge" />
          </CCol>

          <CCol col="12">
            <CInputCheckbox label="Must Specify Name" :checked.sync="guestTypeDefinition.mustSpecifyName" />
          </CCol>
        </CRow>
      </CModal>

      <br />
      <br />
      <br />
      <CDataTable :items="guestGroup.guestTypes" :fields="fields" hover
        v-if="guestGroup.id != emptyGuid && guestGroup.guestTypes.length > 0">
        <template #status="{ item }">
          <td>
            <CBadge :color="getBadge(item.active)">
              {{ item.active == true ? "Active" : "Inactive" }}
            </CBadge>
          </td>
        </template>
        <template #name="{ item }">
          <td>
            <strong>{{ item.name }}</strong>
          </td>
        </template>

        <template #EDIT="{ item }">
          <td style="width: 15%">
            <CButton color="primary" @click="handleEdit(item)">
              <CIcon name="cil-pencil" /> Edit
            </CButton>
          </td>
        </template>
        <template #DELETE="{ item }">
          <td style="width: 15%">
            <CButton color="danger" @click="
              modalTitle =
              'Are You sure to delete selected guest type definition ?';
            modalAction = 'delete';
            schemaId = item.id;
            guestTypeDefinitionModal = true;
            modalType = 'danger';
            guestTypeDefinition = item;
                                                        ">
              <CIcon name="cil-trash" /> Delete
            </CButton>
          </td>
        </template>
      </CDataTable>
    </CCardBody>
  </CCard>
</template>

<script>
import axios from "axios";
export default {
  name: "CreateOrUpdateGuestGroup",
  data: () => {
    return {
      operationName: null,
      emptyGuid: "00000000-0000-0000-0000-000000000000",

      guestGroup: {
        id: "00000000-0000-0000-0000-000000000000",
        name: null,
        active: true,
        askOnlyForMainContact: false,
        guestTypes: [],
      },
      fields: [
        "name",
        "mustSelectAge",
        "mustSpecifyName",
        "minGuests",
        "maxGuests",
        "minAge",
        "maxAge",
        "EDIT",
        "DELETE",
      ],
      modalTitle: null,

      //Alert
      message: null,
      alertType: "danger",

      guestTypeDefinition: {
        id: "00000000-0000-0000-0000-000000000000",
        guestTypeId: "",
        maxAge: null,
        minAge: null,
        mustSpecifyName: false,
        mustSelectAge: false,
        minGuests: null,
        maxGuests: null,
      },
      guestTypeDefinitionModal: false,
      items: [],
      guestTypes: [],
      modalAction: null,
      modalType: "danger",
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    handleEdit(item) {
      this.modalTitle = 'Edit guest type definition';
      this.guestTypeDefinitionModal = true;
      this.modalAction = 'add';
      this.modalType = 'info';
      this.guestTypeDefinition = item;
    },
    closeModal(status, evt, accept) {
      if (accept) {
        if (this.modalAction == "add") {
          this.addGuestTypeDefinition();
        } else if (this.modalAction == "delete") {
          this.deleteGuestTypeDefinition(this.guestTypeDefinition.id);
        } else {
          this.addGuestTypeDefinition();
        }
      }
    },
    deleteGuestTypeDefinition(id) {
      let self = this;
      axios
        .delete(`${this.$apiAdress}/v1/GuestGroup/${self.guestGroup.id}/${id}`)

        .then(function (response) {
                    self.alertType = "success";
          setTimeout(function () {
              self.message = null;
          }, 5000);
          self.message = "Successfully deleted guest type definition.";
          self.cleanGuestTypeDefinition();
          self.get(response.data);
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    },
    getGuestTypes() {
      let self = this;
      axios
        .get(`${this.$apiAdress}/v1/GuestType/GuestTypesList`)
        .then(function (response) {
          self.guestTypes = response.data;
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    },
    get(id) {
      let self = this;
      axios
        .get(`${this.$apiAdress}/v1/GuestGroup/${id}`)
        .then(function (response) {
          self.guestGroup = response.data;
          self.guestGroup.guestTypes.map(function (value, key) {
            value.name = self.guestTypes.find(x => x.value === value.guestTypeId)?.label
          });
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    },
    save() {
      let self = this;
      axios
        .post(`${this.$apiAdress}/v1/GuestGroup`, self.guestGroup)
        .then((response) => {

          self.guestGroup.id = response.data;
                    self.alertType = "success";
          setTimeout(function () {
              self.message = null;
          }, 5000);
          self.message = "Successfully updated guest group.";
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error.response.data.message;
        });
    },
    cleanGuestTypeDefinition() {
      let self = this;
      self.guestTypeDefinition.id = "00000000-0000-0000-0000-000000000000";
      self.guestTypeDefinition.guestTypeId = "00000000-0000-0000-0000-000000000000";
      self.guestTypeDefinition.maxAge = null;
      self.guestTypeDefinition.minAge = null;
      self.guestTypeDefinition.mustSpecifyName = false;
      self.guestTypeDefinition.mustSelectAge = false;
      self.guestTypeDefinition.minGuests = null;
      self.guestTypeDefinition.maxGuests = null;
    },
    addGuestTypeDefinition() {
      let self = this;
      if (self.guestTypeDefinition.maxGuests == "") {
        self.guestTypeDefinition.maxGuests = null;
      }
      axios
        .post(
          `${this.$apiAdress}/v1/GuestGroup/${self.guestGroup.id}`,
          self.guestTypeDefinition
        )
        .then(function (response) {
                    self.alertType = "success";
          setTimeout(function () {
              self.message = null;
          }, 5000);
          self.message = "Successfully updated guest type definition.";
          self.get(response.data);
          self.cleanGuestTypeDefinition();
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    },
  },
  mounted: function () {
    let self = this;
    self.getGuestTypes();

    if (this.$route.params.id != this.emptyGuid) {
      self.operationName = "Edit";
      self.get(this.$route.params.id);
    } else {
      self.operationName = "Create New";
    }
  },
};
</script>
